<template>
  <section>
    <b-row v-if="getBookingDetails">
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col
                cols="12"
                md="12"
                class="d-md-flex d-block justify-content-between align-items-end"
              >
                <div>
                  <h2 class="card-cus-title">تفاصيل الطلب</h2>
                  <p class="card-cus-subtitle">
                    تابع سير عملية الفحص وإلى وأي مرحلة وصل الفحص الخاص بالعميل
                  </p>
                </div>
              </b-col>
              <b-col cols="12" md="12">
                <hr style="border-top: 2px solid #525254" />
                <b-row class="w-100">
                  <b-col v-if="getBookingDetails.indecent_reason" cols="12">
                    <b-alert show variant="danger" class="p-2">
                      <fieldset class="form-group mb-0">
                        <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                          سبب الرفض
                        </legend>
                        <p class="form-control mb-0 d-flex align-items-center bg-inherit">
                          {{ getBookingDetails.indecent_reason }}
                        </p>
                      </fieldset>
                    </b-alert>
                  </b-col>
                  <b-col md="8">
                    <b-row>
                      <b-col md="6">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الطلب
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.id }}#
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            حالة الحجز
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            <b-badge
                              pill
                              :variant="findVariant(getBookingDetails.status)"
                            >
                              {{ findStatus(getBookingDetails.status) }}
                            </b-badge>
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            نوع التحليل
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.analyses_title }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            اسم مقدم الطلب الكامل
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.name }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col md="6">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الهاتف
                          </legend>
                          <p class="form-control mb-0 bg-inherit text-left" dir="ltr">
                            {{ getBookingDetails.phone }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.birth_date" md="6">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            تاريخ الميلاد
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.birth_date }}
                          </p>
                        </fieldset>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col v-if="getBookingDetails.photo" md="4">
                    <fieldset class="form-group">
                      <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                        الصورة الشخصية
                      </legend>
                      <div class="image-after-upload">
                        <img
                          :src="
                            getBookingDetails.photo.full_path +
                            getBookingDetails.photo.file_name
                          "
                          class="img-fluid"
                        />
                      </div>
                    </fieldset>
                  </b-col>
                  <b-col md="12">
                    <b-row>
                      <b-col v-if="getBookingDetails.identification_number" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الهوية
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.identification_number }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.identification_photo" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            صورة الهوية
                          </legend>
                          <div class="image-after-upload">
                            <img
                              :src="
                                getBookingDetails.identification_photo.full_path +
                                getBookingDetails.identification_photo.file_name
                              "
                              class="img-fluid"
                            />
                          </div>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.border_number" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الحدود
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.border_number }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.visa_number" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الفيزة
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.visa_number }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.visa_date" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            تاريخ الفيزة
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.visa_date }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.gender" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            الجنس
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.gender }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.occupation" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            المهنة
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.occupation }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.residency_number" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الإقامة
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.residency_number }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.sponsor_name" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            اسم الكفيل
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.sponsor_name }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col
                        v-if="getBookingDetails.sponsor_identification_number"
                        md="4"
                      >
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الكفيل
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.sponsor_identification_number }}
                          </p>
                        </fieldset>
                      </b-col>

                      <b-col v-if="getBookingDetails.payment_type" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            نوع الدفع
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.payment_type }}
                          </p>
                        </fieldset>
                      </b-col>
                      <b-col v-if="getBookingDetails.deposit_number" md="4">
                        <fieldset class="form-group">
                          <legend class="mb-0 bv-no-focus-ring col-form-label pt-0">
                            رقم الايداع
                          </legend>
                          <p
                            class="form-control mb-0 d-flex align-items-center bg-inherit"
                          >
                            {{ getBookingDetails.deposit_number }}
                          </p>
                        </fieldset>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BCardHeader,
  BCollapse,
  VBToggle,
  BFormTextarea,
  BAlert,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    FormWizard,
    TabContent,
    BFormFile,
    BCardHeader,
    BCollapse,
    VBToggle,
    BFormTextarea,
    BAlert,
  },
  data() {
    return {
      accordion1: false,
      accordion2: false,
      accordion3: true,
      showPaymentConfirmationModal: false,
      showIndecentModal: false,
      payment_type: null,
      deposit_number: null,
      indecent_reason: "",
      examinations: [],
      paymentTypes: ["ايداع عن طريق البنك", "دفع كاش"],
      statusList: [
        {
          id: 1,
          title: "في انتظار الدفع",
        },
        {
          id: 2,
          title: "سحب العينات",
        },
        {
          id: 3,
          title: "الاشعة",
        },
        {
          id: 4,
          title: "الطبيب",
        },
        {
          id: 5,
          title: "إجراء التحاليل",
        },
        {
          id: 6,
          title: "مسؤول المختبر",
        },
        {
          id: 7,
          title: "لائق",
        },
        {
          id: 8,
          title: "غير لائق",
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getBookingDetails: "getBookingDetails",
    }),
  },
  watch: {},
  created() {},
  methods: {
    ...mapActions({
      loadBookingDetails: "loadBookingDetails",
    }),
    findStatus(data) {
      const object = this.statusList.find((item) =>
        item.id === data ? item.title : null
      );
      return object.title;
    },
    findVariant(data) {
      if (data === 1) {
        return "light-warning";
      }
      if (data === 2) {
        return "light-info";
      }
      if (data === 3) {
        return "light-info";
      }
      if (data === 4) {
        return "light-info";
      }
      if (data === 5) {
        return "light-info";
      }
      if (data === 6) {
        return "light-info";
      }
      if (data === 7) {
        return "light-success";
      }
      if (data === 8) {
        return "light-danger";
      }
    },
    checkStatus(status) {
      if (status === 1) {
        return false;
      }
      if (status === 2) {
        if (this.getProfileInfo.role.id === 5) {
          return true;
        }
        if (this.getProfileInfo.role.id === 3) {
          return true;
        }
      }
      if (status === 3) {
        return this.getProfileInfo.role.id === 5;
      }
      if (status === 4) {
        return this.getProfileInfo.role.id === 4;
      }
      if (status === 5) {
        if (this.getProfileInfo.role.id === 5) {
          return true;
        }
        if (this.getProfileInfo.role.id === 3) {
          return true;
        }
      }
    },
  },
  mounted() {
    this.loadBookingDetails(this.$router.currentRoute.params.id);
  },
};
</script>
<style lang="scss">
@import "~@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
